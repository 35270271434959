<template>
	<div class="lithium-battery">
		<div class="header acea-row row-between-wrapper" ref="header">
			<div class="black" @click="returnChange()">
				<img src="@assets/images/icon_black.png" alt="" />
			</div>
			<p>选择企业</p>
<!-- 			<p v-if="type=='2'">电池保护板打样</p>
			<p v-if="type=='3'">锂电池打样</p> -->
		</div>
		<div class="lithium-content">
			<div class="lithium-tips">
				<p>温馨提示：1.请填写以下项目，带*号为必填项; 提交后，我们会有专人与您联系，请保持电话畅通！2.如填写遇到困难，可联系人工客服协助填写！</p>
			</div>

			<div class="lithium-item">
				<div class="company-detail">
					<p>公司性质：{{paramPrice.company_type}}</p>
					<p>公司资质：{{paramPrice.company_qualification}}</p>
					<p>交货期：{{paramPrice.delivery_period}}天</p>
					<p>打样单价（元/Ah）：¥{{paramPrice.price}}</p>
				</div>

			</div>
			<div class="lithium-item" v-if="type==1">
				<p>打样详情</p>
				<div class='content'>
					<div class="acea-row row-between">
						<div class="info">
							<div class="item-list">
								<span>容量：{{paramPrice.capacity}} mAh</span>
							</div>
							<div class="item-list">
								<span>型号：{{paramPrice.xinghao? paramPrice.xinghao:0}}</span>
							</div>
						</div>
						<div class="info">
							<div class="item-list acea-row row-between">
								<span>单价（元/Ah）:</span><span class="price">￥{{paramPrice.price}}</span>
							</div>
							<div class="item-list acea-row row-between">
								<span>样品价格:</span><span class="price">￥{{paramPrice.sample_price}}</span>
							</div>
							<div class="item-list acea-row row-between">
								<span>打样费:</span><span class="price">￥{{paramPrice.proofing_fixed_cost}}</span>
							</div>
						</div>
					</div>
					<div class="acea-row row-between">
						<div class="lit-num">
							<span>打样数量</span>
						</div>
						<div class="lit-num">
							<Spinner min="1" unit="1" v-model="param.num" :callback="getNumData"></Spinner>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="detail-btn acea-row row-between" v-if="type==1 && attr.cartAttr==false && !paramData.name">
			<div class="left bat">
				<div class="acea-row row-center-wrapper" style="height:100%">
					<p class="lit tol">总计：<span>¥{{paramPrice.total_price}}</span></p>
				</div>
			</div>
			<div class="right" @click="joinCart()">
				<p>立即购买</p>
			</div>
		</div>
		<div class="detail-btn acea-row row-between" v-if="paramData.name==1">
	      <div class="right pay bule" :class="firstJum? 'glay':''" @click="joinCart()">
	        <p>确认</p>
	      </div>
	    </div>
		<!-- <div class="detail-btn acea-row row-between" v-if="paramData.name==1">
		    <div class="right sub-success bule">
			    <p>确定</p>
		    </div>
	    </div> -->
	    <div class="dis-contact" @click="getServiceAuto()">
	    	<!-- <router-link to="/customer/list"> -->
	    		<img src="@assets/images/icon_contast1.png" alt="">
	    	    <!-- <span></span> -->
	    	<!-- </router-link> -->
	    </div>

        <batteryPopu
            v-on:changeFun2="changeFun2"
            :storeInfo="paramPrice"
            :attr="attr"
            :typename="'product'"
        ></batteryPopu>

	</div>
</template>
<script type="text/javascript">
import {Spinner} from 'vue-ydui/dist/lib.rem/spinner';
import { getProofingData,getCalc,confirmProofing,createBatteryGroupProofing } from "@api/public";
import { serviceAuto,getUser } from "@api/user";
import { Verify_Empty} from '@utils/dy_validate';
import batteryPopu from "@components/batteryPopu";
export default {
	name:'lithiumBattery',
	components: {
	    Spinner,
	    batteryPopu
	},
	data(){
		return{
			type:1,
			showNum:false,
			selectData:'',           //下拉选项数据
			proofing_fixed_cost:0,  //固定打样费
			paramData:'',           //上一步页面数据
			userInfo:'',
			param:{
				company_brand:1,
				company_name:1,
                num:1,
			},
			paramPrice:{
				capacity:0,     // 容量
                sample_price:0, //样品价格
                total_price:0,  // 总价格
                price:0,        //单价
                proofing_fixed_cost:0,
			},
			attr: {
                cartAttr: false,
                productAttr: [],
                productSelect: {}
            },

			companyBrandList: [],
		}
	},
	watch:{
		$route(n) {
			console.log(n, '------------')
			if (n.name === "lithiumBattery") {
				this.paramData = JSON.parse(n.query.data);
				this.getSelectData();
				this.getUserInfo();
				if(this.param.company_brand && this.param.company_name) {
					this.getPriceData()
				}
			}
		}
	},
	mounted(){
		console.log(this.$route.query.data, '------------')
		this.paramData = JSON.parse(this.$route.query.data);
		this.getSelectData();
		this.getUserInfo();
		if(this.param.company_brand && this.param.company_name) {
			this.getPriceData()
		}
	},
	destroyed(){
	    // console.log(2222)
	},
	methods:{
		//获取用户信息
		getUserInfo(){
			let that = this;
			getUser().then(res => {
				that.userInfo = res.data;
			})
		},
		//进入客服
	    getServiceAuto() {
	      serviceAuto().then(res => {
	        console.log(res);
	        this.$router.push(
	          '/customer/chat/' +
	            res.data.to_uid
	            // +
	            // '/' +
	            // productId +
	            // (orderId ? '?orderId=' + orderId : '')
	        )
	      });
	    },
        //验证表单参数
        validateForm(){
        	if(Verify_Empty(this.paramData.package_type)) {
        		this.$dialog.toast({ mes: "请选择电池封装" });
      		    return false;
        	} else if(Verify_Empty(this.paramData.material_type)) {
        		this.$dialog.toast({ mes: "请选择电池材料" });
      		    return false;
        	} else if(Verify_Empty(this.paramData.discharge_rate)) {
        		this.$dialog.toast({ mes: "请选择电池放电倍率" });
      		    return false;
        	} else if(Verify_Empty(this.paramData.shape_type)) {
        		this.$dialog.toast({ mes: "请选择电芯形状" });
      		    return false;
        	}
        // 	else if(Verify_Empty(this.paramData.delivery_period)) {
          // this.$dialog.toast({ mes: "请输入打样周期" });
          // 	    return false;
          // 	}
          else if(Verify_Empty(this.paramData.long)) {
                this.$dialog.toast({ mes: "请输入长度" });
      		    return false;
        	} else if(Verify_Empty(this.paramData.width)) {
                this.$dialog.toast({ mes: "请输入宽度" });
      		    return false;
        	} else if(Verify_Empty(this.paramData.height)) {
                this.$dialog.toast({ mes: "请输入高度" });
      		    return false;
        	} else if(!this.userInfo.phone) {
				this.$dialog.confirm({
					mes: "未绑定手机号，去绑定手机号?",
					opts: () => {
						this.$router.push({ path: "/user/binding" });
					}
				});
			} else {
        		return true;
        	}
        },

        //点击立即购买
        joinCart: function() {
            //打开加入购物车弹窗
            let that = this;
            if(this.validateForm()) {
                that.attr.cartAttr = true;
            }
        },
        changeFun2: function(opt) {
            let that = this;
            that.attr.cartAttr = opt.value;
            if(opt.index==1) {
            	this.save();
            }
        },

	    //数据提交
	    save(){
	    	if(this.validateForm()) {
	    		var data = {
	    			package_type:this.paramData.package_type,
                    material_type:this.paramData.material_type,
                    discharge_rate:this.paramData.discharge_rate,
                    shape_type:this.paramData.shape_type,
                    long:this.paramData.long,
                    width:this.paramData.width,
                    height:this.paramData.height,
                    delivery_period:this.paramData.delivery_period,
                    num:this.param.num,
                    company_brand:this.paramData.company_brand,
					company_name:this.paramData.company_name,
					proofing_model:this.paramPrice.xinghao,
	    		};
	    		if(!this.paramData.name) {
	    			confirmProofing(data)
		    		.then(res=>{
		    			this.$dialog.success(res.msg).then(() => {
		    		        res.data.type = this.type;
		    		        this.$router.push({ path: "/order/proofingsubmit?data=" + JSON.stringify(res) });
		    		        // this.$router.push({ path: "/order/lithium_lidianchi?type=3" });
			            });
		    		}).catch(res=>{
	            	    this.$dialog.error(res.msg);
	                })
	    		}else {
	    			createBatteryGroupProofing(data)
	    			.then(res=>{
						this.$dialog.success(res.msg).then(() => {
		    		        this.$router.push({ path: "/lithium_lidianchi?type=3" });
			            });
	    			}).catch(res=>{
	    				this.$dialog.error(res.msg);
	    			})
	    		}

	    	}
	    },


		//数量增减回调
		getNumData(){
			this.getNumChange();
		},
		selectCompanyNameChange(e){
			let index = e.target.selectedIndex;
			this.companyBrandList = this.selectData.company_name[index].company_brand;
			if(this.companyBrandList.length>0){
				this.param.company_brand = this.companyBrandList[0].company_brand;
			}
			this.selectChange();
		},
		selectCompanyBrandChange(){
			this.selectChange();
		},
		//选择触发事件
		selectChange(){
			if(this.param.company_brand && this.param.company_name) {
				this.getPriceData()
			}
			// if(this.param.package_type && this.param.material_type && this.param.discharge_rate && this.param.shape_type && this.param.delivery_period){
			// 	this.showNum = true;
			// } else {
			// 	this.showNum = false;
			// }
		},
		//尺寸触发事件
		getNumChange(){
			if(this.paramData.long && this.paramData.width && this.paramData.height && this.paramData.delivery_period) {
				this.getPriceData();
			}
		},
        //获取价格参数
        getPriceData(){
        	var data = {
                package_type:this.paramData.package_type,
                material_type:this.paramData.material_type,
                discharge_rate:this.paramData.discharge_rate,
                shape_type:this.paramData.shape_type,
                long:this.paramData.long,
                width:this.paramData.width,
                height:this.paramData.height,
                delivery_period:this.paramData.delivery_period,
                num:this.param.num,
                company_brand:this.paramData.company_brand,
				company_name:this.paramData.company_name
        	};
            getCalc(data)
            .then(res =>{
            	var l,w,h;
				if(this.paramData.long.indexOf('.')>-1) {
					l = (parseFloat(this.paramData.long)*10).toString();
				} else if(parseInt(this.paramData.long)<10) {
            		l = '0' + this.paramData.long.toString();
            	} else {
            		l = this.paramData.long.toString();
            	}
            	if(parseInt(this.paramData.width)<10) {
            		w = '0' + this.paramData.width.toString();
            	} else {
            		w =this.paramData.width.toString();
            	}
            	if(parseInt(this.paramData.height)<10) {
            		h = '0' + this.paramData.height.toString();
            	} else {
            		h =this.paramData.height.toString();
            	}
        		res.data.xinghao = l +'*'+ w +'*'+ h;
            	this.paramPrice = res.data;
            	this.paramPrice.num = this.param.num;
            })
            .catch(res=>{
            	console.log(res)
            	this.$dialog.error(res.msg);
            })
        },
        //获取下拉选项参数
        getSelectData(){
            getProofingData()
	        .then(res => {
	            this.selectData = res.data.list;
				if(res.data.list.company_name && res.data.list.company_name.length>0){
					this.companyBrandList = res.data.list.company_name[0].company_brand;
					this.param.company_brand = res.data.list.company_name[0].company_brand[0].company_brand;
				}
	            //this.proofing_fixed_cost = res.data.proofing_fixed_cost;
	            //this.paramPrice.proofing_fixed_cost = res.data.proofing_fixed_cost;
	        })
	        .catch(res => {
	            this.$dialog.error(res.msg);
	        });
        },

		//返回上一页
		returnChange() {
	      var that = this;
	      that.$router.go(-1);
	    },
	}
}
</script>
<style type="text/css">
.lithium-battery { padding-top:50px; }

.lithium-battery.router { background:#fff }
.lithium-battery .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
}
.lithium-battery .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
}
.lithium-battery .header .black img {
  width: 100%;
  height: 100%;
}
.lithium-battery .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
}
</style>
